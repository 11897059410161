import classes from './GeneralInfo.module.css';
import founder_img from '../../static/feeney-profile.jpeg';

const GeneralInfo = () => {
    return (
        <div className={classes.general_info}>
            <img src={founder_img} alt="F.G. Feeney, Owner" className={classes.profile_img} />
            {/* Center div and set max-width for optimal styling with large browser width. */}
            <div className={classes.text_section}>
                <p className={classes.gen_inf_para}>
                    Fidelity services all foreign and domestic high and low 
                    speed pneumatic dental handpieces and ultrasonic scalers.
                    We are also trained on the electric high speeds.
                </p>
                <p className={classes.gen_inf_para}>
                    Fidelity pays for USPS domestic shipping and handling with delivery confirmation 
                    both ways, coming and going. To send, please use our USPS Postage Paid Permit 
                    Sticker. (Should you not have one of our boxes ready to use, the Fidelity label 
                    may be downloaded from the web page, printed and taped to any box.) Please leave 
                    the box for your mail carrier to carry away from the office. We return via USPS 
                    first class delivery confirmation. International tariffs and shipping to Fidelity 
                    should be paid for by the handpiece owner, including return tariffs. But Fidelity 
                    will pay return shipping.
                </p>
                <p className={classes.gen_inf_para}>The prices below are your <em>total</em> amount, including all taxes.</p>
                <p>Our practice is to service the instrument within 24 hours upon receipt.</p>
                <p>
                    Established clients, when preparing to send in handpieces for service, may find themselves short.  
                    At that time you may request that Fidelity engage her Free Loaner Program.  We don't want patients 
                    waiting for the autoclave to finish.  Tell us what you would like to borrow, and we will send.
                </p>
            </div>
        </div>
    );
}

export default GeneralInfo;